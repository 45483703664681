import React from "react";
import SEO from "components/seo";
import { injectIntl } from "gatsby-plugin-intl";
import CtaButton from "components/cta-button";
import Rodal from 'rodal';
import { withPrefix } from "gatsby";
import Share from "images/share.inline.svg";
import countries from "utils/countries";

import "./take-action.scss";
import 'rodal/lib/rodal.css';

class TakeAction extends React.PureComponent {

  state = {
    formModalIsOpen: false,
    successModalIsOpen: false,
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    eOpt: false,
    phoneOpt: false,
    smsOpt: false,
    phoneNumber: "",
    loading: false,
    countries: [],
    displayErrors: false
  }

  componentDidMount() {
    this.setState({
      countries: countries
    })
  }

  handleClose = () => {
    this.setState({
      formModalIsOpen: false
    });
  }

  takeAction = () => {
    this.setState({
      formModalIsOpen: true
    });
  }

  validateEmail(email) {
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/;
    if (reg.test(email) === false) {
      return false;
    } else {
      return true;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      this.setState({ displayErrors: true });
      return;
    }
    this.setState({ displayErrors: false })

    const data = {
      page: "Equalizer API Action",
      campaign: "https://actions.oxfam.org/api/1-0/campaign/1203/",
      language: this.props.intl.locale,
      email: this.state.email,
      telephone_id: this.state.phoneNumber,
      form_data: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        country: this.state.country,
        e_opt: this.state.eOpt,
        "telephone opt-in": this.state.phoneOpt,
        "SMS opt-in": this.state.smsOpt
      }
    }
    this.setState({
      loading: true
    });

    fetch("https://actions.oxfam.org/api/1-0/action/", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (response.status === 200 || response.status === 201) {
        response.json().then(responseData => {
          this.setState({
            loading: false,
            formModalIsOpen: false
          }, () => {
            setTimeout(() => {
              this.setState({
                successModalIsOpen: true,
              }, () => {
                setTimeout(() => {
                  this.setState({
                    successModalIsOpen: false
                  })
                }, 5000);
              }, 300); // Rodal animation default time
            })
          });
        });
      } else {
        this.setState({
          loading: false
        });
        window.console.warn(`Looks like there was a problem. Status Code ${response.status}`);
      }
    });

  }

  share = () => {
    const url = 'https://facebook.com/sharer.php?display=popup&u=' + window.location.href;
    const options = 'toolbar=0,status=0,resizable=1,width=626,height=436';
    window.open(url, 'sharer', options);
  }

  render() {
    const { displayErrors } = this.state;

    const { intl } = this.props;
    let paragraphDesktopCount = 0;
    let paragraphMobileCount = 0;
    let paragraphDesktop = "";
    let paragraphMobile = "";

    while (intl.messages[`take-action-page.paragraph--desktop.${paragraphDesktopCount}`]) {
      paragraphDesktop = paragraphDesktop + intl.messages[`take-action-page.paragraph--desktop.${paragraphDesktopCount}`];
      if (intl.messages[`take-action-page.paragraph--desktop.${paragraphDesktopCount + 1}`] !== undefined) {
        paragraphDesktop = paragraphDesktop + "<br><br>";
      }
      paragraphDesktopCount = paragraphDesktopCount + 1;
    }

    while (intl.messages[`take-action-page.paragraph--mobile.${paragraphMobileCount}`]) {
      paragraphMobile = paragraphMobile + intl.messages[`take-action-page.paragraph--mobile.${paragraphMobileCount}`];
      if (intl.messages[`take-action-page.paragraph--mobile.${paragraphMobileCount + 1}`] !== undefined) {
        paragraphMobile = paragraphMobile + "<br><br>";
      }
      paragraphMobileCount = paragraphMobileCount + 1;
    }

    return (
      <>
        <SEO title="Take action" />
        <SEO title={intl.formatMessage({ id: "take-action-page.page-title" })} description={intl.formatMessage({ id: "take-action-page.page-description" })} />
        <div className="take-action">
          <h1 className="fs--xl color--yellow">{intl.formatMessage({ id: "take-action-page.title" })}</h1>
          <div className="take-action__copy take-action__copy--desktop color--white"><p dangerouslySetInnerHTML={{ __html: paragraphDesktop }}></p></div>
          <div className="take-action__copy take-action__copy--mobile color--white"><p dangerouslySetInnerHTML={{ __html: paragraphMobile }}></p></div>
          <CtaButton onClick={this.takeAction} title={intl.formatMessage({ id: "take-action-page.cta-button" })} />
          <p className="take-action__end-text color--white">
            {intl.formatMessage({ id: "take-action-page.end-text" })} <a className="color--white" target="new" href={withPrefix(`/${intl.locale}/letter`)}>{intl.formatMessage({ id: "take-action-page.end-text-link" })}</a>
          </p>
          <Rodal className="oxfam-form" visible={this.state.formModalIsOpen} customStyles={{ width: "100%", height: "auto" }} onClose={this.handleClose} enterAnimation="slideUp" leaveAnimation="slideDown">
            <div className="oxfam-form__container">
              <form className={displayErrors ? 'displayErrors' : ''} noValidate onSubmit={this.handleSubmit}>
                <input required name="firstName" placeholder={`${intl.formatMessage({ id: "take-action-page.form.first-name" })}*`} type="text" value={this.state.firstName} onChange={event => this.setState({ firstName: event.target.value })} />
                <input required placeholder={`${intl.formatMessage({ id: "take-action-page.form.last-name" })}*`} type="text" value={this.state.lastName} onChange={event => this.setState({ lastName: event.target.value })} />
                <input required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder={`${intl.formatMessage({ id: "take-action-page.form.email" })}*`} type="text" value={this.state.email} onChange={event => this.setState({ email: event.target.value })} />
                <input placeholder={intl.formatMessage({ id: "take-action-page.form.phone-number" })} type="text" value={this.state.phoneNumber} onChange={event => this.setState({ phoneNumber: event.target.value })} />
                <div className="styled-select">
                  <select required value={this.state.country} onChange={event => this.setState({ country: event.target.value })}>
                    <option disabled value="">{`${intl.formatMessage({ id: "take-action-page.form.country" })}*`}</option>
                    {this.state.countries.map((country, key) => {
                      return (
                        <option key={key} value={country}>{country}</option>
                      );
                    })}
                  </select>
                </div>
                <p className="privacy-text" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "take-action-page.form.privacy-text" }) }}></p>
                <label className="checkbox">
                  <input type="checkbox" checked={this.state.eOpt} onChange={event => this.setState({ eOpt: event.target.checked })} />
                  <span className="checkmark"></span>
                  {intl.formatMessage({ id: "take-action-page.form.email-opt" })}
                </label>
                <label className="checkbox">
                  <input type="checkbox" checked={this.state.phoneOpt} onChange={event => this.setState({ phoneOpt: event.target.checked })} />
                  <span className="checkmark"></span>
                  {intl.formatMessage({ id: "take-action-page.form.phone-opt" })}
                </label>
                <label className="checkbox">
                  <input type="checkbox" checked={this.state.smsOpt} onChange={event => this.setState({ smsOpt: event.target.checked })} />
                  <span className="checkmark"></span>
                  {intl.formatMessage({ id: "take-action-page.form.sms-opt" })}
                </label>
                <button disabled={this.state.loading}><span>{this.state.loading ? "Submitting" : intl.formatMessage({ id: "take-action-page.form.submit" })}</span></button>
              </form>
            </div>
          </Rodal>
          <Rodal customStyles={{ width: "100%", height: "auto" }} onClose={() => this.setState({ successModalIsOpen: false })} visible={this.state.successModalIsOpen} enterAnimation="slideUp" leaveAnimation="slideDown">
            <div className="success">
              <h2 className="fs--lg color--purple">{intl.formatMessage({ id: "take-action-page.form.success-title" })}</h2>
              <p className="text">{intl.formatMessage({ id: "take-action-page.form.success-text" })}</p>
              <Share onClick={this.share} />
              <p className="share">{intl.formatMessage({ id: "take-action-page.form.share-text" })}</p>
            </div>
          </Rodal>
        </div>
      </>
    )
  }
}


export default injectIntl(TakeAction)
